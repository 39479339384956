import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from '../layout/Layout';
import Home from '../pages/Home';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermCondition from '../pages/TermCondition';

const Router: React.FC = () => {
    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/term-condition" element={<TermCondition />} />
                </Routes>
            </Layout>
            
        </BrowserRouter>
    );
}

export default Router;
