// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/images/hero_back.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-section{
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.hero-section::before {
    content: '';
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center center / cover no-repeat; 
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -111;
    opacity: 0.9;
}

.hero-section::after {
    content: '';
    background-color: rgb(56, 55, 55, 0.4); 
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.9;
}

.background{
    background-color: rgb(56, 55, 55, 0.5);
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/stylesheet/heroSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,mFAAiF;IACjF,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,sCAAsC;IACtC,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,sCAAsC;IACtC,kBAAkB;AACtB","sourcesContent":[".hero-section{\n    height: 400px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n}\n\n.hero-section::before {\n    content: '';\n    background: url('../assets/images/hero_back.png') center center / cover no-repeat; \n    height: 100%;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: -111;\n    opacity: 0.9;\n}\n\n.hero-section::after {\n    content: '';\n    background-color: rgb(56, 55, 55, 0.4); \n    height: 100%;\n    width: 100%;\n    position: absolute;\n    top: 0;\n    left: 0;\n    z-index: -1;\n    opacity: 0.9;\n}\n\n.background{\n    background-color: rgb(56, 55, 55, 0.5);\n    border-radius: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
