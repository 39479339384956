import React from 'react';
import Logo from '../assets/images/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import '../stylesheet/footer.css';
import { useMediaQuery } from '@mui/material';

const Footer: React.FC = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    return (
        <section className='footer pt-3' style={{ backgroundColor: 'rgb(82,82,223,0.2)' }}>
            <div className='d-flex align-items-center justify-content-between ps-4 pe-4' style={{flexDirection: isMobile ? 'column' : 'row'}}>
                <div className='d-flex align-items-center justify-content-center gap-2'>
                    <img src={Logo} alt="LogoImage" style={{ height: '50px', width: '50px' }} />
                    <h5>FitCheckAI</h5>
                </div>
                <div className='d-flex align-items-center justify-content-center' style={{flexDirection: isMobile ? 'column' : 'row', gap: isMobile ? '5px': '3rem'}}>
                    <Link target='_blank' to='/privacy-policy'>Privacy Policy</Link>
                    <Link target='_blank' to='/term-condition'>Terms of Service</Link>
                    <Link target='_blank' to='/'>Contact US : xxx@gmail.com</Link>
                </div>
                <div className="d-flex align-items-center justify-content-center gap-4" style={{marginTop: isMobile? '10px' : '0px'}}>
                    <Link to="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['fab', 'facebook']} size="2x" style={{ color: '#4267B2' }} />
                    </Link>
                    <Link to="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['fab', 'youtube']} size="2x" style={{ color: '#FF0000' }} />
                    </Link>
                    <Link to="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['fab', 'x-twitter']} size="2x" style={{ color: 'black' }} />
                    </Link>
                    <Link to="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" style={{ color: '#0A66C2' }} />
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default Footer;