import React from 'react';
import Header from '../layout/Header';

const TermCondition: React.FC = () => {
    return (
        <>
            <Header />
            <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', color: '#333' }}>
                <h1 style={{ textAlign: 'center', color: '#2c3e50', marginBottom: '20px' }}>Terms and Conditions</h1>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    Welcome to FitCheckAI ! These terms and conditions outline the rules and regulations for the use of Crispy Fhres's App, Drag Racing Lights.
                </p>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    By accessing this app, we assume you accept these terms and conditions. Do not continue to use Drag Racing Lights if you do not agree to all of the terms and conditions stated on this page.
                </p>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    The following terminology applies to these Terms and Conditions, Privacy Statement, and Disclaimer Notice and all Agreements: "Client", "You", and "Your" refers to you, the person using this app and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our", and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves.
                </p>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services, in accordance with and subject to, prevailing law of the Netherlands. Any use of the above terminology or other words in the singular, plural, capitalization, and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
                </p>
                <h2 style={{ fontSize: '18px', color: '#2c3e50', marginTop: '20px' }}>Cookies</h2>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    We employ the use of cookies. By accessing Drag Racing Lights, you agreed to use cookies in agreement with the Crispy Fhres's Privacy Policy.
                </p>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our app to enable the functionality of certain areas to make it easier for people visiting our app. Some of our affiliate/advertising partners may also use cookies.
                </p>
                <h2 style={{ fontSize: '18px', color: '#2c3e50', marginTop: '20px' }}>License</h2>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    Unless otherwise stated, Crispy Fhres and/or its licensors own the intellectual property rights for all material on Drag Racing Lights. All intellectual property rights are reserved. You may access this from Drag Racing Lights for your own personal use subjected to restrictions set in these terms and conditions.
                </p>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    You must not:
                </p>
                <ul style={{ fontSize: '16px', lineHeight: '1.6', marginLeft: '20px' }}>
                    <li>Republish material from Drag Racing Lights</li>
                    <li>Sell, rent, or sub-license material from Drag Racing Lights</li>
                    <li>Reproduce, duplicate, or copy material from Drag Racing Lights</li>
                    <li>Redistribute content from Drag Racing Lights</li>
                </ul>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    This Agreement shall begin on the date hereof.
                </p>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    Parts of this app offer an opportunity for users to post and exchange opinions and information in certain areas of the app. Crispy Fhres does not filter, edit, publish or review Comments prior to their presence on the app. Comments do not reflect the views and opinions of Crispy Fhres, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, Crispy Fhres shall not be liable for the Comments or for any liability, damages, or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this app.
                </p>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    Crispy Fhres reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or causes breach of these Terms and Conditions.
                </p>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    You warrant and represent that:
                </p>
                <ul style={{ fontSize: '16px', lineHeight: '1.6', marginLeft: '20px' }}>
                    <li>You are entitled to post the Comments on our app and have all necessary licenses and consents to do so;</li>
                    <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent, or trademark of any third party;</li>
                    <li>The Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful material which is an invasion of privacy</li>
                    <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
                </ul>
                <p style={{ fontSize: '16px', lineHeight: '1.6' }}>
                    You hereby grant Crispy Fhres a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats, or media.
                </p>
                <p style={{ fontSize: '16px', lineHeight: '1.6', marginTop: '20px' }}>
                    For further details on our Terms and Conditions, please contact us.
                </p>
            </div>
        </>
    );
}

export default TermCondition;
